<template>
  <main>
    <div class="container">
      <!-- Header -->
      <header class="user__header">
        <navigations-breadcrumb :children="breadcrumbs" />
        <h1 class="user__title">{{ $t('resto.user_orders_title')}}</h1>
      </header>

      <!-- Loading -->
      <div
        class="user__loader"
        v-if="loading">
        <ui-loader />
      </div>

      <!-- Body -->
      <div
          class="user__body flow"
          v-if="!loading">
        <card-order
          :key="i"
          :order="order"
          v-for="(order, i) in orders"
        />

        <data-empty
          size="s"
          :title="$t('resto.user_no_orders')"
          :description="$t('resto.user_no_orders_description')"
          :primary-action="$t('resto.go_order_now')"
          @click="goOnCatalog"
          v-if="!hasOrders"
        />

        <router-view />
      </div>
    </div>
  </main>
</template>

<script>
import { mapState } from 'vuex'
import NavigationsBreadcrumb from '@/components/navigations/breadcrumb'
import CardOrder from '../../components/ui/card-order'

import MixinMultishop from '@/helpers/multishop'

export default {
  inject: ['$user'],

  components: {
    CardOrder,
    NavigationsBreadcrumb,
  },

  mixins: [MixinMultishop],

  data() {
    return {
      breadcrumbs: [
        { label: this.$t('resto.user_account_title'), href: {name: 'sayl-front-user.home'} },
        { label: this.$t('resto.user_orders_title'), href: '#' }
      ],
      loading: true,
    }
  },

  computed: {
    ...mapState({
      service: state => state['sayl-front.service'].service,
    }),

    orders(){
      let ret = [];

      if(!this.loading) {
        ret = this.$user.orders;
      }

      return ret;
    },

    hasOrders() {
      return this.orders.length > 0;
    },

    user() {
      return this.$basil.get(this.$user, 'user')
    },
  },

  methods: {
    goOnCatalog() {
      if(this.service && this.service.name) {
        this.$router.push({
          name: this.isMultiShop ? 'sayl-front-catalog.shops' : 'sayl-front-catalog.catalog',
          params: {
            embed: this.$route.params.embed,
            service: this.service.name,
          }
        }).catch(() => {})
        return
      }
      this.$router.push({
        name: 'sayl-front-catalog.services',
        params: {
          embed: this.$route.params.embed,
        }
      }).catch(() => {})
    }
  },

  mounted(){
    this.loading = true

    if(!this.user || !this.user.isLogged) {
      this.$router.replace({name: 'sayl-front-user.login'}).catch(() => {})
      return
    }

    this.$user.getOrders({ limit: -1, page: 1 })
      .catch((e) => $console.error(e))
      .finally(() => this.loading = false)
  }
}
</script>
